import React from 'react';
import { Modal } from 'react-bootstrap';

const TermsConditionsModal = ({ open, close }) => {
  return (
    <Modal show={open} onHide={close} size="lg" dialogClassName="terms-modal">
      <Modal.Header closeButton>
        <Modal.Title>Terms and Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="p-3">
          <p>In using Anna, you agree to these terms and conditions:</p>
          <ol className="m-0" type="A">
            <li>All responses and correspondences with Anna will be recorded.</li>
            <li>
              Information such as name (required), age (required), sex (required), senior high school strand (required), and related
              correspondence will be for the exclusive use of this study to continuously improve Anna.
            </li>
            <li>The data collected will be used for as long as it is needed for further analysis or investigation.</li>
            <li>You are free to exit the conversation with Anna if you feel the need to do so.</li>
          </ol>
        </div>

        <div className="p-3">
          <h1 className="h5 custom-heading text-primary">USER GUIDELINES:</h1>
          <p>Anna could only converse in the English language. It is then recommended that your responses be in English.</p>
          <p>
            If the user is idle for more than 20 minutes, Anna would end the conversation by replying with phrases like, "I think I lost you
            there. Please do reach out to me again anytime. I'll be here 😊". If this happens, greeting Anna with words like "Hello", or "Hi",
            will start a new conversation.
          </p>
          <p className="mb-1">
            If any problems occur during the conversation process, or you have any suggestions or comments you would like to share with the
            researchers, please leave a feedback. Your insights and suggestions would help improve our project.
          </p>
        </div>

        <div className="p-3">
          <h1 className="h5 custom-heading text-primary">CONFIDENTIALITY</h1>
          <p>
            The information that Anna will be obtaining throughout the conversation will remain confidential to protect your rights or
            welfare.
          </p>
          <p>
            RA 10173 or the Data Privacy Act protects individuals from unauthorized processing of personal information. To ensure that your
            information is protected, the researchers will follow this law to keep your information safe and confidential.
          </p>
        </div>

        <div className="p-3">
          <h1 className="h5 custom-heading text-primary">DEFINITIONS</h1>
          <p>
            Throughout the conversation, Anna will be responding to possible jargons. To ensure that you understand Anna, the definition of
            words will be provided:
          </p>
          <p className="mb-1">
            <span className="fw-bold">Degree Program</span> - A class that a college or university offers to students. (Bachelor of Science
            in Information Technology, etc..)
          </p>
          <p className="mb-1">
            <span className="fw-bold">RIASEC</span> - A personality test that asks about your interest, skills, ability, and aspirations
            which will help you decide on what career to pursue based on these attributes.
          </p>
          <p className="mb-1">
            <span className="fw-bold">Senior high school strand</span> - Disciplines that are offered by schools to senior high school
            students that would prepare them for college.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={close}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsConditionsModal;
