import React, { useState, useEffect, useContext } from 'react';
import { Col, Nav, Button } from 'react-bootstrap';
import { FaHome, FaCommentDots, FaClipboardList, FaSignOutAlt, FaTasks } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { toast } from 'react-toastify'; // Import toast for error messages
import api from '../../utils/api'; // Import the API utility

const Navigation = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const { setIsAuthenticated } = useContext(UserContext);
  const navigate = useNavigate();

  const getSubdomain = () => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    if (parts.length > 2) {
      return parts[0]; // Return the subdomain
    }
    return null; // No subdomain
  };

  const handleLogout = () => {
    // Clear authentication tokens
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    
    // Set authentication state to false
    setIsAuthenticated(false);
    
    // Redirect based on subdomain
    const subdomain = getSubdomain();
    const redirectUrl = subdomain
      ? `https://${subdomain}.annaguidance.ai/admin/login`
      : 'https://annaguidance.ai/admin/login';
    window.location.href = redirectUrl;
  };

  const toggleSidebar = () => setIsSidebarCollapsed((prevState) => !prevState);

  const handleNavigation = (path) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      console.warn('No token found, redirecting to login.');
      handleLogout();
    } else {
      navigate(path);
    }
  };

  const validateUserRole = async () => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) throw new Error('No token found');
      
      const response = await api.get('/check_login_status/', {
        headers: { Authorization: `Bearer ${token}` },
      });

      const user = response.data.user_data;
      if (!user.is_staff && !user.is_superuser) {
        throw new Error('Unauthorized access');
      }
    } catch (error) {
      console.error('Role validation failed:', error);
      toast.error('Unauthorized access. You have been logged out.');
      handleLogout();
    }
  };

  useEffect(() => {
    validateUserRole(); // Validate user role on mount
  }, []);

  const navItems = [
    { path: '/admin/dashboard', label: 'Dashboard', icon: <FaHome /> },
    { path: '/admin/conversation', label: 'Conversations', icon: <FaClipboardList /> },
    { path: '/admin/feedback', label: 'Feedback', icon: <FaCommentDots /> },
    { path: '/admin/management', label: 'Management', icon: <FaTasks /> },
  ];

  const NavItem = ({ path, label, icon }) => (
    <Nav.Item className="mb-3">
      <Nav.Link
        onClick={() => handleNavigation(path)}
        className="text-white fw-bold d-flex align-items-center"
        style={{
          justifyContent: isSidebarCollapsed ? 'center' : 'flex-start',
          textAlign: isSidebarCollapsed ? 'center' : 'left',
          flexDirection: 'row',
          transition: 'all 0.3s ease',
          fontSize: '1rem',
          padding: '10px 15px',
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.color = 'white';
          e.currentTarget.querySelector('svg').style.fill = 'white';
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.color = '';
          e.currentTarget.querySelector('svg').style.fill = '';
        }}
      >
        <span
          style={{
            minWidth: '30px',
            textAlign: 'center',
          }}
        >
          {React.cloneElement(icon, { size: isSidebarCollapsed ? 30 : 20 })}
        </span>
        {!isSidebarCollapsed && <span className="ms-2">{label}</span>}
      </Nav.Link>
    </Nav.Item>
  );

  return (
    <Col
      xs="auto"
      className="bg-warning d-flex flex-column"
      style={{
        width: isSidebarCollapsed ? '80px' : '200px',
        transition: 'width 0.3s ease',
        overflowY: 'auto',
        overflowX: 'hidden',
        minHeight: '100vh',
        paddingBottom: '20px',
      }}
    >
      <Button
        variant="link"
        className="text-white align-self-center"
        onClick={toggleSidebar}
        style={{ marginTop: '20px', marginBottom: '40px' }}
      >
        <span className="navbar-toggler-icon"></span>
      </Button>

      <Nav className="flex-column" style={{ marginTop: '20px' }}>
        {navItems.map((item) => (
          <NavItem key={item.path} path={item.path} label={item.label} icon={item.icon} />
        ))}
      </Nav>

      <Nav.Item className="mt-auto mb-4">
        <Button
          variant="danger"
          className="fw-bold d-flex align-items-center"
          onClick={handleLogout}
          style={{
            justifyContent: isSidebarCollapsed ? 'center' : 'flex-start',
            fontSize: '0.9rem',
            padding: '10px 15px',
            width: '100%',
          }}
        >
          <span
            style={{
              minWidth: '30px',
              textAlign: 'center',
            }}
          >
            <FaSignOutAlt size={isSidebarCollapsed ? 25 : 20} />
          </span>
          {!isSidebarCollapsed && <span className="ms-2">Logout</span>}
        </Button>
      </Nav.Item>
    </Col>
  );
};

export default Navigation;
