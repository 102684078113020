import React, { useEffect, useState } from 'react';
import '../../styles/style.css';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { toast } from 'react-toastify';
import * as bootstrap from 'bootstrap';
import api from '../../utils/api'; // Import the Axios instance
import Navbar from '../pages/Navbar'; // Import the Navbar component
function AccountDetails() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id_no: '',
    full_name: '',
    email: '',
    mobile_no: '',
    birthday: '',
    strand: '',
    sex: '',
    grade_level: '',
    section_id: '', // Include section_id for dropdown
  });
  const [profilePicture, setProfilePicture] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [sections, setSections] = useState([]); // Section dropdown data

  // Check if passwords match
  useEffect(() => {
    setPasswordsMatch(newPassword === confirmNewPassword);
  }, [newPassword, confirmNewPassword]);

  // Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) throw new Error('No auth token found');

        const response = await api.get('/auth/user/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const formattedBirthday = response.data.birthday
          ? new Date(response.data.birthday).toISOString().split('T')[0]
          : '';

        setFormData({
          id_no: response.data.id_no || '',
          full_name: response.data.full_name || '',
          email: response.data.email || '',
          mobile_no: response.data.mobile_no || '',
          birthday: formattedBirthday,
          strand: response.data.strand || '',
          sex: response.data.sex || '',
          grade_level: response.data.grade_level || '',
          section_id: response.data.section_id || '',
        });

        setProfilePicture(
          response.data.profile_picture || 'https://via.placeholder.com/150?text=No+Image'
        );

        // Fetch sections based on the user's school_id
        const sectionResponse = await api.get(`/sections/?school_id=${response.data.school_id}`);
        setSections(sectionResponse.data || []);
      } catch (error) {
        console.error('Error fetching user data:', error.response ? error.response.data : error.message);
        toast.error('Failed to fetch user data');
      }
    };

    fetchUserData();
  }, []);

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  // Handle profile picture upload
  const handleProfilePicUpload = async (file) => {
    if (!file) {
      toast.error('No file selected');
      return;
    }

    const formData = new FormData();
    formData.append('profile_picture', file);

    try {
      const response = await api.post('/auth/upload-profile-picture/', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        setProfilePicture(URL.createObjectURL(file)); // Update profile picture preview
        toast.success('Profile picture uploaded successfully');
      } else {
        toast.error('Failed to upload profile picture');
      }
    } catch (error) {
      console.error('Error uploading profile picture:', error.response ? error.response.data : error.message);
      toast.error('Error uploading profile picture');
    }
  };

  // Handle password change
  // Handle password change
const handlePasswordChange = async (e) => {
  e.preventDefault();

  if (!passwordsMatch) {
    toast.error('New passwords do not match!');
    return;
  }

  try {
    const response = await api.put(
      '/auth/change-password/',
      {
        current_password: currentPassword,
        new_password: newPassword,
        confirm_new_password: confirmNewPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      }
    );

    if (response.status === 200) {
      toast.success('Password updated successfully');
      const modal = document.getElementById('changePasswordModal');
      const modalInstance = bootstrap.Modal.getInstance(modal);
      modalInstance.hide();
      document.querySelectorAll('.modal-backdrop').forEach((backdrop) => backdrop.remove());
    }
  } catch (error) {
    // Handle backend error responses
    if (error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error); // Display the specific error message
    } else {
      console.error('Error updating password:', error.message);
      toast.error('An unexpected error occurred while updating the password');
    }
  }
};


  // Handle account details update
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.put('/auth/user/', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      if (response.status === 200) {
        toast.success('Account details updated successfully');
      } else {
        toast.error('Failed to update account details');
      }
    } catch (error) {
      console.error('Error updating account details:', error.response ? error.response.data : error.message);
      toast.error('Error updating account details');
    }
  };

  return (
    <div className="container-fluid p-0">
      {/* Navbar */}
      <Navbar />
  
      {/* Main Content */}
      <div
  className="d-flex justify-content-center align-items-start" // Align content to the top
  style={{
    minHeight: 'calc(100vh - 45px)', // Adjust to account for navbar height
    backgroundColor: '#f9f9f9',
    paddingTop: '20px', // Reduced padding for compact spacing
  }}
>
  <div
    className="card shadow-sm"
    style={{
      width: '100%',
      maxWidth: '1000px', // Standardized card size
      padding: '30px', // Adjusted padding for compact spacing
      borderRadius: '20px',
      backgroundColor: '#f0f0f0', // Gray background for card
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow
    }}
  >
          <div className="card-body">
            <h5 className="card-subtitle mb-4 text-muted text-center">Account Details</h5>
            <div className="row g-4">
              <div className="col-md-4 text-center">
                <img
                  src={profilePicture}
                  alt="Profile"
                  className="rounded-circle mx-auto mb-3 shadow"
                  style={{
                    width: '200px', // Profile image size
                    height: '200px',
                    objectFit: 'cover',
                    border: '3px solid #ffc107',
                  }}
                />
                <button
                  type="button"
                  className="btn btn-warning btn-sm"
                  style={{
                    width: '150px', // Fixed button width
                    marginTop: '10px',
                  }}
                  onClick={() =>
                    document.getElementById('profilePicInput').click()
                  }
                >
                  Upload
                </button>
                <input
                  type="file"
                  id="profilePicInput"
                  style={{ display: 'none' }}
                  onChange={(e) => handleProfilePicUpload(e.target.files[0])}
                />
              </div>
              <div className="col-md-8">
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <label htmlFor="full_name" className="form-label">
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="full_name"
                        value={formData.full_name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="grade_level" className="form-label">
                        Grade Level
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="grade_level"
                        value={formData.grade_level}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="mobile_no" className="form-label">
                        Mobile No.
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="mobile_no"
                        value={formData.mobile_no}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <label htmlFor="birthday" className="form-label">
                        Birthday
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="birthday"
                        value={formData.birthday}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6">
  <label htmlFor="strand" className="form-label">
    Strand
  </label>
  <select
    className="form-select"
    id="strand"
    value={formData.strand}
    onChange={handleChange}
    required
  >
    <option value="" hidden>
      Select Strand
    </option>
    <option value="ABM">ABM</option>
    <option value="ARTS & DESIGN">ARTS & DESIGN</option>
    <option value="STEM">STEM</option>
    <option value="HUMSS">HUMSS</option>
    <option value="TVL - Information and Communications Technology">
      TVL - Information and Communications Technology
    </option>
    <option value="TVL - Home Economics">TVL - Home Economics</option>
    <option value="TVL - Agri-Fishery Arts">TVL - Agri-Fishery Arts</option>
    <option value="TVL - Industrial Arts">TVL - Industrial Arts</option>
  </select>
</div>

                  </div>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <label htmlFor="section_id" className="form-label">
                        Section
                      </label>
                      <select
                        className="form-select"
                        id="section_id"
                        value={formData.section_id}
                        onChange={handleChange}
                      >
                        <option value="" hidden>
                          Select Section
                        </option>
                        {sections.map((section) => (
                          <option key={section.id} value={section.id}>
                            {section.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="sex" className="form-label">
                        Sex
                      </label>
                      <select
                        className="form-select"
                        id="sex"
                        value={formData.sex}
                        onChange={handleChange}
                        required
                      >
                        <option value="" hidden>
                          Select Sex
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    <button type="submit" className="btn btn-warning w-50">
                      Save
                    </button>
                  </div>
                </form>
                <div className="text-center mt-4">
  {/* Change Password Button */}
  <button
    type="button"
    className="btn btn-warning"
    data-bs-toggle="modal"
    data-bs-target="#changePasswordModal"
    style={{
      fontSize: '1rem',
      padding: '10px 20px',
      borderRadius: '6px',
      fontWeight: 'bold',
    }}
  >
    Change Password
  </button>

  {/* Change Password Modal */}
  <div
    className="modal fade"
    id="changePasswordModal"
    tabIndex="-1"
    aria-labelledby="changePasswordModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="changePasswordModalLabel">
            Change Password
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <form onSubmit={handlePasswordChange}>
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="currentPassword" className="form-label">
                Current Password
              </label>
              <input
                type="password"
                className="form-control"
                id="currentPassword"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="newPassword" className="form-label">
                New Password
              </label>
              <input
                type="password"
                className="form-control"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="confirmNewPassword" className="form-label">
                Confirm New Password
              </label>
              <input
                type="password"
                className="form-control"
                id="confirmNewPassword"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                required
              />
              {!passwordsMatch && (
                <div className="text-danger mt-2">
                  Passwords do not match.
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-warning"
              disabled={!passwordsMatch}
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
  );
  
  
}

export default AccountDetails;
