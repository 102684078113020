import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import api from '../../utils/api';
import { TailSpin } from 'react-loader-spinner';
import Navbar from '../pages/Navbar'; // Import Navbar
import jsPDF from 'jspdf'; // Import jsPDF
import html2canvas from 'html2canvas'; // Import html2canvas

const Results = () => {
  const [conversationData, setConversationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const resultsRef = useRef(null); // Ref for the results container

  // Fetch student result data
  useEffect(() => {
    const fetchResults = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          throw new Error('No auth token found');
        }

        const response = await api.get('/auth/get-conversations/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.conversations && response.data.conversations.length > 0) {
          setConversationData(response.data.conversations[0]); // Use the first conversation
        } else {
          setConversationData(null);
        }

        setLoading(false);
      } catch (error) {
        console.error(
          'Error fetching conversation data:',
          error.response ? error.response.data : error.message
        );
        toast.error('Failed to fetch results');
        setLoading(false);
      }
    };

    fetchResults();
  }, []);

  // PDF Download Function
  const downloadAsPDF = async () => {
    const element = resultsRef.current; // Get the results container
    const canvas = await html2canvas(element, {
      scale: 2, // Increase the scale for better quality
      useCORS: true, // Allow cross-origin images
    });
    const imgData = canvas.toDataURL('image/png'); // Convert canvas to image
    const pdf = new jsPDF('p', 'mm', 'a4'); // Create a new PDF
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width; // Scale the image to fit the PDF

    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight); // Add the image to the PDF
    pdf.save('results.pdf'); // Save the PDF file
  };

  // Extract the top 20 RIASEC and Strand recommendations
  const getTopRecommendations = (recommendations) => recommendations.slice(0, 20);

  if (loading) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center vh-100">
        <TailSpin color="#ffcc00" height={100} width={100} />
        <p className="mt-3" style={{ color: '#555', fontSize: '1.2rem' }}>
          Fetching results, please wait...
        </p>
      </div>
    );
  }

  if (!conversationData) {
    return (
      <div className="container d-flex flex-column align-items-center justify-content-center vh-100">
        <i className="bi bi-exclamation-circle" style={{ fontSize: '5rem', color: '#ffcc00' }}></i>
        <h2 className="mt-3" style={{ color: '#555' }}>No Results Found</h2>
        <p className="text-muted">
          We couldn't find any matching results. Please try again later or go back to check other options.
        </p>
        <button
          className="btn btn-warning mt-3"
          style={{ fontSize: '1rem', padding: '10px 20px' }}
          onClick={() => navigate(-1)}
        >
          Go Back
        </button>
      </div>
    );
  }

  const topRiasec = getTopRecommendations(conversationData.riasec_course_recommendation);
  const topStrand = getTopRecommendations(conversationData.strand_course_recommendation);

  return (
    <div className="container-fluid p-0">
      {/* Navbar */}
      <Navbar />
{/* Floating PDF Download Button */}
<div
  className="position-fixed"
  style={{
    bottom: '20px',
    right: '20px',
    zIndex: 1050,
  }}
>
  <button
    className="btn btn-primary rounded-circle shadow"
    onClick={downloadAsPDF}
    style={{
      width: '60px',
      height: '60px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <i className="bi bi-arrow-down-circle"></i>

  </button>
</div>



      {/* Main Content */}
      <div
        ref={resultsRef} // Attach the ref to this container
        className="container mt-3 p-4 shadow-sm bg-light rounded"
      >
        {/* Centered Student Information Section */}
        <div className="row mb-5 justify-content-center text-center">
          <div className="col-md-2">
            <strong className="d-block mb-2" style={{ fontSize: '1.2rem' }}>Student Name</strong>
            <p className="text-muted" style={{ fontSize: '1rem' }}>{conversationData.name}</p>
          </div>
          <div className="col-md-2">
            <strong className="d-block mb-2" style={{ fontSize: '1.2rem' }}>Age</strong>
            <p className="text-muted" style={{ fontSize: '1rem' }}>{conversationData.age}</p>
          </div>
          <div className="col-md-2">
            <strong className="d-block mb-2" style={{ fontSize: '1.2rem' }}>Sex</strong>
            <p className="text-muted" style={{ fontSize: '1rem' }}>{conversationData.sex}</p>
          </div>
          <div className="col-md-2">
            <strong className="d-block mb-2" style={{ fontSize: '1.2rem' }}>Strand</strong>
            <p className="text-muted" style={{ fontSize: '1rem' }}>{conversationData.strand}</p>
          </div>
          <div className="col-md-2">
            <strong className="d-block mb-2" style={{ fontSize: '1.2rem' }}>Test Taken Date</strong>
            <p className="text-muted" style={{ fontSize: '1rem' }}>
              {new Date(conversationData.created_at).toLocaleDateString()}
            </p>
          </div>
        </div>

        {/* RIASEC Code */}
        <div className="row text-center mb-4 d-flex justify-content-center">
          {conversationData.riasec_code.map((code, index) => (
            <div key={index} className="col-md-2 col-4 mb-3">
              <div className="bg-warning text-white rounded py-4">
                <strong className="d-block">{code[0].toUpperCase()}</strong>
                <span className="fs-4">{code[1]}</span>
              </div>
            </div>
          ))}
        </div>

        {/* Suggestions */}
        <div className="suggestions mt-4">
          <h4 className="text-center mb-4">ANNA RECOMMENDATIONS</h4>

          <div className="row">
            {/* RIASEC Courses */}
            <div className="col-md-6 mb-3">
              <h5 className="text-center">Top 20 RIASEC Courses</h5>
              <div className="card border-0 shadow-sm">
                <ul className="list-group list-group-flush border rounded">
                  {topRiasec.map((course, index) => (
                    <li key={index} className="list-group-item py-2">
                      {course}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Strand Courses */}
            <div className="col-md-6 mb-3">
              <h5 className="text-center">Top 20 Strand Courses</h5>
              <div className="card border-0 shadow-sm">
                <ul className="list-group list-group-flush border rounded">
                  {topStrand.map((course, index) => (
                    <li key={index} className="list-group-item py-2">
                      {course}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Results;
