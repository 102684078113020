import React, { useEffect, useRef, useState, useContext, Fragment } from 'react';
import { v4 as uuid } from 'uuid';
import Cookies from 'universal-cookie';
import { ChatbotContext } from '../../context/ChatbotContext';
import { MdClose, MdSend } from 'react-icons/md';
import { FaUserGraduate } from 'react-icons/fa';
import Feedback from '../pages/FeedbackModal'; 

import Message from './Message';
import Card from './Card';
import chathead from '../../assets/Anna_Chathead.svg';
import chatloading from '../../assets/chatbot-loading.gif';
import chatbotAvatar from '../../assets/Anna_Chat_Avatar.svg';
import QuickReplies from './QuickReplies';
import Modal from '../Modal';
import RecommendedCoursesMessage from './RecommendedCoursesMessage';
import RecommendedCoursesQuickReply from './RecommendedCoursesQuickReply';
import { titleCase } from '../../utils/utilityFunctions';
import api from '../../utils/api'
import '../../styles/chatbot.css';

const cookies = new Cookies();
const Chatbot = () => {
   const [showFeedbackModal, setShowFeedbackModal] = useState(false);
   const [refresh, setRefresh] = useState(false);
   const [messages, setMessages] = useState([
     {
       speaks: 'bot',
       keyword: 'terms-conditions',
       msg: {
         text: {
           text: 'Hello. Before we begin, in this session I will take your basic information. You must first read and agree on the terms presented in the',
         },
       },
     },
   ]);
   const [textMessage, setTextMessage] = useState('');
   const {
     isAgreeTermsConditions,
     setIsAgreeTermsConditions,
     showBot,
     setShowbot,
     inputRef,
     disabledInput,
     setDisabledInput,
     setIsVisibleInput,
     isVisibleInput,
     botChatLoading,
     setBotChatLoading,
     basis,
     setBasis,
     setIsRecommendationProvided,
     isRecommendationProvided,
   } = useContext(ChatbotContext);
 
   const messagesRef = useRef(null);
   const [courseOptionsTimer, setCourseOptionsTimer] = useState('');
   const [user, setUser] = useState({ name: '', age: '', sex: '', strand:'' });
   const [riasec, setRiasec] = useState({ realistic: 0, investigative: 0, artistic: 0, social: 0, enterprising: 0, conventional: 0 });
   const [riasecCode, setRiasecCode] = useState([]);
   const [fallbackCount, setFallbackCount] = useState({});
   const [endConversation, setEndConversation] = useState(false);
   const [isBasicInfoProvided, setIsBasicInfoProvided] = useState(false);
   const [testStarted, setTestStarted] = useState(false);
   const [knownCourses, setKnownCourses] = useState([]);
   const [riasecBasedRecommendedCourses, setRiasecBasedRecommendedCourses] = useState([]);
   const [strandBasedRecommendedCourses, setStrandBasedRecommendedCourses] = useState([]);
   const [isLoggedIn, setIsLoggedIn] = useState(false);
   const [isStrandRecommendationTriggered, setIsStrandRecommendationTriggered] = useState(false);

   if (!cookies.get('userId')) cookies.set('userId', uuid(), { path: '/' });
   const fetchUserInfo = async () => {
      try {
          const accessToken = localStorage.getItem('authToken');
          const refreshToken = localStorage.getItem('refreshToken');
  
          if (!accessToken) {
              setIsLoggedIn(false);
              setUser(null);
              return;
          }
  
          const checkLoginStatus = async (token) => {
              return api.get('/api/check_login_status/', {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  },
              });
          };
  
          let response = await checkLoginStatus(accessToken);
  
          if (response.status === 401 && refreshToken) {
              const refreshResponse = await api.post('/api/token/refresh/', {
                  refresh: refreshToken,
              });
  
              const refreshData = refreshResponse.data;
              if (refreshResponse.status === 200) {
                  localStorage.setItem('authToken', refreshData.access);
                  response = await checkLoginStatus(refreshData.access);
              } else {
                  setIsLoggedIn(false);
                  setUser(null);
                  return;
              }
          }
  
          const data = response.data;
          if (response.status === 200 && data.is_logged_in && data.user_data) {
              setUser({
                  name: data.user_data.name,
                  age: data.user_data.age,
                  sex: data.user_data.sex,
                  strand: data.user_data.strand,
                  chatbot_starts: data.user_data.chatbot_starts,
                  max_chatbot_starts: data.user_data.max_chatbot_starts,
              });
              setIsLoggedIn(true);
              setIsBasicInfoProvided(true);
  
              if (isAgreeTermsConditions && !testStarted) {
                  // Check chatbot starts before allowing test
                  if (data.user_data.chatbot_starts < data.user_data.max_chatbot_starts) {
                      console.log('Starting RIASEC test for user:', data.user_data.name);
                      const welcomeMessage = {
                          speaks: 'bot',
                          msg: { text: { text: `Hello ${data.user_data.name}! Welcome back. Let's get started with your RIASEC test.` } },
                      };
                      setMessages((prev) => [...prev, welcomeMessage]);
                      await df_event_query('RIASEC_START');
                      setTestStarted(true);
                  } else {
                      console.log('Max chatbot starts reached. Test cannot be started.');
                      setShowbot(false);
                  }
              }
          } else {
              setIsLoggedIn(false);
              setUser(null);
              setIsBasicInfoProvided(false);
          }
      } catch (error) {
          console.error('Error fetching user info:', error);
          setIsLoggedIn(false);
          setUser(null);
      }
  };
   useEffect(() => {
     fetchUserInfo();
   }, []);
 
   useEffect(() => {
      // Listen for chatbot refresh event after login
      const handleChatbotRefresh = () => {
        // Re-fetch the user info and refresh the chatbot state
        fetchUserInfo();  // This will trigger the chatbot to refresh with the user's data
      };
    
      window.addEventListener('chatbotRefresh', handleChatbotRefresh);
    
      // Cleanup the event listener
      return () => {
        window.removeEventListener('chatbotRefresh', handleChatbotRefresh);
      };
    }, []);
    
  
    useEffect(() => {
      // Re-fetch the necessary chatbot state or trigger RIASEC_START
      // You can fetch the user info here or refresh the chatbot state
    }, [refresh]);
   useEffect(() => {
      const handleLoginSuccess = (e) => {
        const userData = e.detail;
    
        setUser({
          name: userData.name,
          age: userData.age,
          sex: userData.sex,
          strand: userData.strand,
        });
    
        // Remove the df_event_query('RIASEC_START') from here
      };
    
      window.addEventListener('loginSuccess', handleLoginSuccess);
    
      return () => {
        window.removeEventListener('loginSuccess', handleLoginSuccess);
      };
    }, []);
  
  
  
    const df_text_query = async (text, parameters = {}) => {
      const userSays = {
          speaks: 'user',
          msg: {
              text: {
                  text: text,
              },
          },
      };
  
      // Remove any preceding quick reply message
      if (messages[messages.length - 1]?.msg?.payload?.fields?.quick_replies) {
          removeQuickRepliesAfterType(messages, setMessages);
      }
  
      setMessages((prev) => [...prev, userSays]);
      setBotChatLoading(true);
  
      try {
          const body = { text, userId: cookies.get('userId'), parameters };
          console.log('Sending text query to Dialogflow:', body);
  
          const response = await fetch('https://node-backend-604521917673.asia-northeast1.run.app/api/df_text_query/', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(body),
          });
  
          const data = await response.json();
          setBotChatLoading(false);
          console.log('Dialogflow response:', data);
  
          if (response.status !== 200 || !data) {
              throw new Error('Failed to process Dialogflow text query');
          }
  
          // Handle responses based on intents
          if (data.intent?.displayName === 'Default Welcome Intent') {
              clearState();
              return;
          }
  
          if (!data.intent || endConversation) {
              console.warn('No matching intent or end of conversation detected.');
              df_event_query('FALLBACK_EXCEED_TRIGGER_LIMIT');
              clearState();
              setEndConversation(true);
              return;
          }
  
          // Handle fallback intents
          if (data.intent?.isFallback) {
              const intentName = data.intent.displayName;
              fallbackCount[intentName] = (fallbackCount[intentName] || 0) + 1;
  
              if (fallbackCount[intentName] >= 5) {
                  console.warn('Fallback limit exceeded for intent:', intentName);
                  df_event_query('FALLBACK_EXCEED_TRIGGER_LIMIT');
                  clearState();
                  setEndConversation(true);
                  return;
              }
          }
  
          // Update user data based on parameters
          const fields = data.parameters?.fields;
          if (fields) {
              if (fields.name?.stringValue) setUser((prev) => ({ ...prev, name: fields.name.stringValue }));
              if (fields.age?.numberValue) setUser((prev) => ({ ...prev, age: fields.age.numberValue }));
              if (fields.sex?.stringValue) setUser((prev) => ({ ...prev, sex: fields.sex.stringValue }));
              if (fields.strand?.stringValue) setUser((prev) => ({ ...prev, strand: fields.strand.stringValue }));
          }
  
          // Handle fulfillment messages
          data.fulfillmentMessages.forEach((msg) => {
              const botSays = {
                  speaks: 'bot',
                  msg: msg,
              };
              setMessages((prev) => [...prev, botSays]);
  
              const payloadFields = msg.payload?.fields;
  
              if (payloadFields?.riasec?.stringValue) {
                  const riasecValue = payloadFields.riasec.stringValue;
                  console.log('RIASEC value received:', riasecValue);
  
                  setRiasec((prev) => {
                      const updatedRiasec = { ...prev };
                      switch (riasecValue) {
                          case 'realistic':
                              updatedRiasec.realistic += 1;
                              break;
                          case 'investigative':
                              updatedRiasec.investigative += 1;
                              break;
                          case 'artistic':
                              updatedRiasec.artistic += 1;
                              break;
                          case 'social':
                              updatedRiasec.social += 1;
                              break;
                          case 'enterprising':
                              updatedRiasec.enterprising += 1;
                              break;
                          case 'conventional':
                              updatedRiasec.conventional += 1;
                              break;
                          default:
                              console.warn('Unknown RIASEC value:', riasecValue);
                      }
                      return updatedRiasec;
                  });
              }
  
              if (payloadFields?.iswant_strand_recommendation?.boolValue && !isStrandRecommendationTriggered) {
                  console.log('Triggering strand recommendation...');
                  df_event_query('STRAND_RECOMMENDATION', { strand: user.strand });
                  setIsStrandRecommendationTriggered(true); // Prevent future triggers
                  setIsRecommendationProvided((prev) => ({ ...prev, strand: 'done' }));
              }
  
              if (payloadFields?.riasec_last_question?.boolValue && !isRecommendationProvided.riasec) {
                  handleRiasecRecommendation(riasec);
                  setIsRecommendationProvided((prev) => ({ ...prev, riasec: 'done' }));
              }
  
              if (payloadFields?.end_conversation?.boolValue) {
                  console.log('Ending conversation...');
                  savedConversation(user, riasecCode, riasecBasedRecommendedCourses, strandBasedRecommendedCourses);
                  clearState();
                  setDisabledInput(true);
                  setIsVisibleInput(false);
                  setIsStrandRecommendationTriggered(false); // Reset for next conversation
              }
          });
      } catch (err) {
          console.error('Error in Dialogflow text query:', err.message);
          setBotChatLoading(false);
  
          const botSays = {
              speaks: 'bot',
              msg: {
                  text: {
                      text: 'Sorry. I am having trouble 🤕. I need to terminate. Will be back later.',
                  },
              },
          };
          setMessages((prev) => [...prev, botSays]);
      }
  };
  

  const df_event_query = async (event, parameters = {}) => {
   try {
       setBotChatLoading(true);

       const body = {
           event,
           userId: cookies.get('userId'),
           parameters,
       };

       console.log('Sending event to Dialogflow:', body);

       const response = await fetch(
           'https://node-backend-604521917673.asia-northeast1.run.app/api/df_event_query/',
           {
               method: 'POST',
               headers: { 'Content-Type': 'application/json' },
               body: JSON.stringify(body),
           }
       );

       const data = await response.json();
       setBotChatLoading(false);

       if (response.status !== 200) {
           throw new Error(`Failed to fetch Dialogflow event. Status: ${response.status}`);
       }

       console.log('Dialogflow response:', data);

       // Append fulfillment messages to `messages` state
       if (data.fulfillmentMessages) {
           data.fulfillmentMessages.forEach((msg) => {
               const botSays = {
                   speaks: 'bot',
                   msg,
               };
               setMessages((prev) => [...prev, botSays]); // Append to messages state
           });
       } else {
           console.warn('No fulfillment messages found in response.');
       }

       // Handle Default Welcome Intent
       if (data.intent?.displayName === 'Default Welcome Intent') {
           console.log('Triggered Default Welcome Intent.');
           return; // Do not clear state
       }

       // Handle fallback intents or unknown intents
       if (!data.intent) {
           console.warn('No intent matched. Triggering fallback...');
           await df_event_query('FALLBACK_EXCEED_TRIGGER_LIMIT');
           clearState();
           setEndConversation(true);
           setDisabledInput(false);
           setIsVisibleInput(true);
           return;
       }

       // Process fulfillment messages with additional payload fields
       data.fulfillmentMessages.forEach((msg) => {
           const fields = msg.payload?.fields;

           if (!fields) {
               console.warn('No fields found in payload. Skipping message:', msg);
               return;
           }

           // Handle strand recommendation trigger
           if (fields.iswant_strand_recommendation?.boolValue && !isStrandRecommendationTriggered) {
               console.log('Triggering strand recommendation...');
               df_event_query('STRAND_RECOMMENDATION', { strand: user.strand });
               setIsStrandRecommendationTriggered(true);
               setIsRecommendationProvided((prev) => ({ ...prev, strand: 'done' }));
           }

           // Handle RIASEC logic
           if (fields.riasec?.stringValue) {
               const riasecValue = fields.riasec.stringValue;
               console.log('Received RIASEC value:', riasecValue);

               setRiasec((prev) => {
                   const updatedRiasec = { ...prev };
                   switch (riasecValue) {
                       case 'realistic':
                           updatedRiasec.realistic += 1;
                           break;
                       case 'investigative':
                           updatedRiasec.investigative += 1;
                           break;
                       case 'artistic':
                           updatedRiasec.artistic += 1;
                           break;
                       case 'social':
                           updatedRiasec.social += 1;
                           break;
                       case 'enterprising':
                           updatedRiasec.enterprising += 1;
                           if (fields.riasec_last_question?.boolValue) {
                               handleRiasecRecommendation(updatedRiasec);
                           }
                           break;
                       case 'conventional':
                           updatedRiasec.conventional += 1;
                           break;
                       default:
                           console.warn('Unknown RIASEC value:', riasecValue);
                           break;
                   }
                   return updatedRiasec;
               });
           }

           // Handle recommendations
           if (fields.riasec_recommended_courses?.listValue?.values) {
               const courses = fields.riasec_recommended_courses.listValue.values.map(
                   (course) => course.stringValue
               );
               console.log('RIASEC Recommended Courses:', courses);
               setRiasecBasedRecommendedCourses(courses);
           }

           if (fields.strand_recommended_courses?.listValue?.values) {
               const courses = fields.strand_recommended_courses.listValue.values.map(
                   (course) => course.stringValue
               );
               console.log('Strand Recommended Courses:', courses);
               setStrandBasedRecommendedCourses(courses);
           }

           // Handle end of conversation
           if (fields.end_conversation?.boolValue) {
               console.log('Ending conversation...');
               savedConversation(user, riasecCode, riasecBasedRecommendedCourses, strandBasedRecommendedCourses);
               clearState();
               setDisabledInput(true);
               setIsVisibleInput(false);
           }
       });
   } catch (err) {
       console.error('Error sending event to Dialogflow:', err.message);

       setBotChatLoading(false);

       // Add a generic error message to the `messages` state
       const botSays = {
           speaks: 'bot',
           msg: {
               text: {
                   text: 'Sorry, something went wrong 🤕. Please try again later.',
               },
           },
       };
       setMessages((prev) => [...prev, botSays]);
   }
};

  
  

   const triggerCourseOptionYes = () => {
      // this will keep the context exceeds the time limit of 20mins, because users might take time watching the videos
      // after the  course options was rendered, trigger the course option timer after 15 minutes to reset the timer of the intent's context
      // will be cleared after clicking "continue" quick reply
      // only trigger the timer when courseOptionsTimer is empty or no courseOptiontimer tiggered to avoid duplication of timer when component is rendered again
      // timer will still tigger even card component is unmounted
      if (!courseOptionsTimer) {
         const timerId = setInterval(async () => {
            try {
               const body = { event: 'COURSE_OPTIONS_YES', userId: cookies.get('userId') };
               await fetch('https://node-backend-604521917673.asia-northeast1.run.app/api/df_event_query', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(body),
               });
               console.log('course options timer triggered!');
            } catch (err) {
               console.error(err.message);
            }
         }, 900000);
         setCourseOptionsTimer(timerId);
      }
   };

   const clearCourseOptionsYes = () => {
      // clear the timer
      clearInterval(courseOptionsTimer);
      setCourseOptionsTimer('');
      console.log('course options timer cleared');
   };

   const clearState = () => {
      setUser({ name: '', age: '', sex: '', strand: '' });
      setRiasec({ realistic: 0, investigative: 0, artistic: 0, social: 0, enterprising: 0, conventional: 0 });
      setRiasecCode([]);
      setKnownCourses([]);
      setRiasecBasedRecommendedCourses([]);
      setStrandBasedRecommendedCourses([]);
      setFallbackCount({});
      setEndConversation(false);
      setBasis('');
      setIsRecommendationProvided({ riasec: '', strand: '' });
   };

   const handleRiasecRecommendation = riasecScores => {
      const sortRiasec = Object.entries(riasecScores).sort(([, a], [, b]) => b - a);
      console.log('\nsort riasec = ', sortRiasec);
      const sameScore = sortRiasec.filter(el => sortRiasec[0][1] === el[1]);
      console.log('sameScore = ', sameScore);

      // sort the riasec
      // get the riasec areas where same as the  highes riasec score
      // if sameScore as highes score is > 3 then randomly select among those riasec areas, else get the top 3 from the sortRiasec
      let RIASEC_CODE = [];

      if (sameScore.length > 3) {
         // mag randomly pick among those highes riasec score as the RIASEC code
         for (let i = 1; i <= 3; i++) {
            const random = Math.floor(Math.random() * sameScore.length); // random index number based on the sameScore.length
            RIASEC_CODE.push(...sameScore.splice(random, 1)); // uses random delete which return a value -> to avoid duplicated value of RIASEC, then stored to new array
         }
      } else RIASEC_CODE = sortRiasec.slice(0, 3);

      console.log('RIASEC CODE = ', RIASEC_CODE);

      setRiasecCode(RIASEC_CODE);
      df_event_query('RIASEC_RECOMMENDATION', RIASEC_CODE);
      setIsRecommendationProvided(prev => ({ ...prev, riasec: 'done' }));
      // fetchCoursesByStrand();
   };

   const handleRecommendedCourseClick = course => {
      const allMessages = messages;
      let userSays = {
         speaks: 'user',
         msg: {
            text: {
               text: course,
            },
         },
      };

      // remove quick reply message
      messages.pop();
      setMessages([...allMessages, userSays]);
      if (!knownCourses.includes(course)) setKnownCourses(prev => [...prev, course]);

      if (basis === 'riasec') df_event_query('GET_RIASEC_RECOMMENDATION_COURSE_INFO', { course_to_lookup: course });
      else if (basis === 'strand') df_event_query('GET_STRAND_RECOMMENDATION_COURSE_INFO', { course_to_lookup: course });
   };

   const refreshAccessToken = async () => {
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
          console.error('No refresh token found.');
          return null;
      }
  
      try {
          // Update the endpoint to match your Django backend
          const response = await api.post('/api/token/refresh/', { refresh: refreshToken });
          const { access } = response.data;
  
          if (access) {
              localStorage.setItem('authToken', access);
              console.log('Access token refreshed successfully.');
              return access;
          } else {
              console.error('No access token returned during refresh.');
              return null;
          }
      } catch (error) {
          console.error('Token refresh failed:', error.message);
          return null;
      }
  };
  
  
  const savedConversation = async (user, riasecCode, riasecCourses, strandCourses) => {
   try {
       // Check if the user is logged in
       const token = localStorage.getItem('authToken');

       // Prepare body data
       const body = {
           name: titleCase(user.name), // Title case the name
           age: user.age, // Include age for guests only
           sex: user.sex,
           strand: user.strand,
           riasec_code: riasecCode, // Array of RIASEC codes
           riasec_course_recommendation: riasecCourses, // Array of recommended courses based on RIASEC
           strand_course_recommendation: strandCourses, // Array of strand-based recommended courses
           realistic_score: riasec.realistic, // Realistic score
           investigative_score: riasec.investigative, // Investigative score
           artistic_score: riasec.artistic, // Artistic score
           social_score: riasec.social, // Social score
           enterprising_score: riasec.enterprising, // Enterprising score
           conventional_score: riasec.conventional // Conventional score
       };

       // Select endpoint based on login status
       const endpoint = token
           ? '/api/save-conversation/' // Logged-in users
           : '/api/save-guest-conversation/'; // Guests

       let headers = {};
       if (token) {
           headers = { Authorization: `Bearer ${token}` };
       }

       // Send data to the backend
       const response = await api.post(endpoint, body, { headers });

       if (response && response.status === 201) {
           console.log('Conversation saved successfully:', response.data.message);

           // Increment chatbot_starts after successfully saving the conversation
           if (token) {
               await incrementChatbotStarts();
           }
       } else {
           console.error('Failed to save conversation:', response);
       }
   } catch (error) {
       if (error.response && error.response.status === 401 && token) {
           console.warn('Access token expired or invalid, attempting to refresh...');
           const newToken = await refreshAccessToken(); // Refresh token if expired

           if (!newToken) {
               console.error('Failed to refresh access token.');
               return;
           }

           // Retry saving the conversation with the refreshed token
           try {
               const retryResponse = await api.post('/api/save-conversation/', body, {
                   headers: {
                       Authorization: `Bearer ${newToken}`
                   }
               });
               console.log('Conversation saved successfully after token refresh:', retryResponse.data.message);

               // Increment chatbot_starts after retry
               await incrementChatbotStarts();
           } catch (retryError) {
               console.error('Error saving conversation after token refresh:', retryError.response?.data || retryError.message);
           }
       } else {
           console.error('Error saving conversation:', error.response?.data || error.message);
       }
   }
};

const incrementChatbotStarts = async () => {
   try {
       const token = localStorage.getItem('authToken');
       const response = await api.patch(
           '/api/increment-chatbot-starts/',
           {},
           {
               headers: {
                   Authorization: `Bearer ${token}`
               }
           }
       );

       if (response.status === 200) {
           console.log('Chatbot starts incremented:', response.data.chatbot_starts);
       } else {
           console.warn('Unable to increment chatbot starts:', response.data.error);
       }
   } catch (error) {
       console.error('Error incrementing chatbot starts:', error.response?.data || error.message);
   }
};


   const renderCards = cards => {
      return cards.map((card, i) => <Card key={i} payload={card.structValue} />);
   };

   const renderMessage = (message, i) => {
      if (message.msg && message.msg.text && message.msg.text.text) {
         return (
            <Message key={i} keyword={message.keyword} terms={message.terms && message.terms} speaks={message.speaks} text={message.msg.text.text} />
         );
      } else if (message.msg && message.msg.payload.fields.cards) {
         return (
            <Fragment key={i}>
               <div className='message-cards'>
                  <img className='chatbot-avatar message-avatar' src={chatbotAvatar} alt='chathead' />
                  <div className='cards'>
                     <div style={{ width: message.msg.payload.fields.cards.listValue.values.length * 270 }}>
                        {renderCards(message.msg.payload.fields.cards.listValue.values)}
                     </div>
                  </div>
               </div>
               {message.msg.payload.fields.quick_replies && (
                  <QuickReplies
                     triggerCourseOptionYes={triggerCourseOptionYes}
                     clearCourseOptionsYes={clearCourseOptionsYes}
                     isCardQuickReplies={message.msg.payload.fields.cards ? true : false}
                     messages={messages}
                     setMessages={setMessages}
                     replyClick={handleQuickReplyPayload}
                     payload={message.msg.payload.fields.quick_replies.listValue.values}
                  />
               )}
            </Fragment>
         );
      } else if (
         message.msg &&
         message.msg.payload &&
         message.msg.payload.fields &&
         message.msg.payload.fields.quick_replies &&
         message.msg.payload.fields.basis &&
         message.msg.payload.fields.recommended_courses_info
      ) {
         return (
            <RecommendedCoursesQuickReply
               key={i}
               payload={message.msg.payload.fields.quick_replies.listValue.values}
               basis={message.msg.payload.fields.basis.stringValue}
            />
         );
      } else if (message.msg && message.msg.payload && message.msg.payload.fields && message.msg.payload.fields.quick_replies) {
         return (
            <QuickReplies
               key={i}
               messages={messages}
               setMessages={setMessages}
               replyClick={handleQuickReplyPayload}
               isRiasecQuickReplies={message.msg.payload.fields.isriasec_quick_replies && message.msg.payload.fields.isriasec_quick_replies.boolValue}
               payload={message.msg.payload.fields.quick_replies.listValue.values}
            />
         );
      } else if (
         message.msg &&
         message.msg.payload &&
         message.msg.payload.fields &&
         message.msg.payload.fields.basis &&
         (message.msg.payload.fields.riasec_recommended_courses || message.msg.payload.fields.strand_recommended_courses)
      ) {
         return (
            <RecommendedCoursesMessage
               key={i}
               speaks={message.speaks}
               isRecommendationProvided
               handleMessagesScrollToBottom={handleMessagesScrollToBottom}
               dialogflowEventQuery={df_event_query}
               setTextMessage={setTextMessage}
               strand={user.strand}
               basis={message.msg.payload.fields.basis.stringValue}
               recommendedCourses={
                  message.msg.payload.fields.basis.stringValue === 'riasec'
                     ? message.msg.payload.fields.riasec_recommended_courses.listValue.values
                     : message.msg.payload.fields.strand_recommended_courses.listValue.values
               }
            />
         );
      }
   };

   const renderMessages = messages => {
      if (messages && messages.length > 0) {
         return messages.map((message, i) => {
            return renderMessage(message, i);
         });
      } else return null;
   };

   const send = e => {
      e.preventDefault();
      df_text_query(textMessage);
      setTextMessage('');
   };

   const handleMessagesScrollToBottom = () => {
      // element.scrollTop = element.scrollHeight - element is the container of message
      // for automatic scoll when new message -> messagesRef.current.scrollTop = messagesRef.current.scrollHeight
      // for smooth scrolling, added scroll-behavior: smooth in css for chatbot-messaes class
      // if (messagesRef.current) messagesRef.current.scrollIntoView({ behavior: 'smooth' });
      if (messagesRef.current) messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
   };

   const removeQuickRepliesAfterType = (messages, setMessages) => {
      const allMessages = messages;
      messages.pop();
      setMessages(allMessages);
   };

   const handleQuickReplyPayload = (e, payload, text) => {
      e.preventDefault(); // will only work for <a> tag or buttons submit
      e.stopPropagation(); // will only work for <a> tag or buttons submit

      let humanSays = {
         speaks: 'user',
         msg: {
            text: {
               text: text,
            },
         },
      };

      switch (payload) {
         case 'COURSE_OPTIONS_YES':
            setMessages(prev => [...prev, humanSays]);
            df_event_query('COURSE_OPTIONS_YES');
            break;

         case 'RIASEC_START':
            setMessages(prev => [...prev, humanSays]);
            df_event_query('RIASEC_START');
            break;

         case 'ISLEARN_RIASEC_RECOMMENDED_COURSES_YES':
            setMessages(prev => [...prev, humanSays]);
            df_event_query('ISLEARN_RIASEC_RECOMMENDED_COURSES_YES');
            break;

         case 'ISWANT_STRAND_RECOMMENDATION':
            setMessages(prev => [...prev, humanSays]);
            df_event_query('ISWANT_STRAND_RECOMMENDATION');
            break;

         case 'ISLEARN_STRAND_RECOMMENDED_COURSES_YES':
            setMessages(prev => [...prev, humanSays]);
            df_event_query('ISLEARN_STRAND_RECOMMENDED_COURSES_YES');
            break;

         case 'END_CONVERSATION':
            setMessages(prev => [...prev, humanSays]);
            df_event_query('END_CONVERSATION');
            break;

         default:
            df_text_query(text);
            break;
      }
   };

   const handleResolveAfterXSeconds = x => {
      setBotChatLoading(true);
      return new Promise(resolve => {
         setTimeout(() => {
            setBotChatLoading(false);
            resolve(x);
         }, x * 1000);
      });
   };

   const handleTermsConditionAgree = async () => {
      setIsAgreeTermsConditions(true); // Set agreement to true
  
      try {
          // Get tokens from localStorage
          let accessToken = localStorage.getItem('authToken');
          const refreshToken = localStorage.getItem('refreshToken');
  
          if (!accessToken) {
              // If no token is present, proceed as a guest
              console.warn('No access token found. Proceeding as guest.');
              df_event_query('Welcome'); // Trigger Welcome intent for guests
              return;
          }
  
          // Function to check login status
          const checkLoginStatus = async (token) => {
              return await api.get('/api/check_login_status/', {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  },
              });
          };
  
          // Function to check chatbot starts limit
          const checkMaxChatbotStarts = async (token) => {
              const response = await api.get('/api/check-max-chatbot-starts/', {
                  headers: { Authorization: `Bearer ${token}` },
              });
              return response;
          };
  
          let response = await checkLoginStatus(accessToken);
  
          // Refresh token if access token is invalid
          if (response.status === 401 && refreshToken) {
              const refreshResponse = await api.post('/api/token/refresh/', {
                  refresh: refreshToken,
              });
  
              if (refreshResponse.status === 200) {
                  const refreshData = refreshResponse.data;
                  accessToken = refreshData.access;
                  localStorage.setItem('authToken', accessToken);
                  response = await checkLoginStatus(accessToken); // Retry with new token
              } else {
                  console.warn('Failed to refresh token. Proceeding as guest.');
                  localStorage.removeItem('authToken');
                  localStorage.removeItem('refreshToken');
                  df_event_query('Welcome'); // Trigger Welcome intent for guests
                  return;
              }
          }
  
          if (response.status === 200 && response.data.is_logged_in) {
              // Check if the chatbot starts limit is reached
              const limitResponse = await checkMaxChatbotStarts(accessToken);
  
              if (limitResponse.status === 200 && limitResponse.data.is_limit_exceeded) {
                  console.log('Max chatbot starts reached.');
                  const limitReachedMessage = {
                      speaks: 'bot',
                      msg: {
                          text: {
                              text: `Hello ${response.data.user_data.name}, you have reached the maximum number of chatbot sessions allowed. Please contact your guidance counselor for further assistance.`,
                          },
                      },
                  };
                  setMessages((prev) => [...prev, limitReachedMessage]);
                  setDisabledInput(true);
              } else {
                  // Proceed with the RIASEC test
                  setUser({
                      name: response.data.user_data.name,
                      age: response.data.user_data.age,
                      sex: response.data.user_data.sex,
                      strand: response.data.user_data.strand,
                  });
  
                  const welcomeMessage = {
                      speaks: 'bot',
                      msg: {
                          text: {
                              text: `Hello ${response.data.user_data.name}! Welcome back. Let's get started with your RIASEC test.`,
                          },
                      },
                  };
                  setMessages((prev) => [...prev, welcomeMessage]);
  
                  // Trigger RIASEC start event
                  df_event_query('RIASEC_START');
              }
          } else {
              console.warn('User not logged in. Proceeding as guest.');
              df_event_query('Welcome'); // Proceed as a guest
          }
      } catch (error) {
          console.error('Error checking user login status:', error.message);
          // Display fallback error
          const errorMessage = {
              speaks: 'bot',
              msg: {
                  text: {
                      text: 'Something went wrong while checking your access. Please try again later.',
                  },
              },
          };
          setMessages((prev) => [...prev, errorMessage]);
      }
  };
  
  
  
   // const fetchCoursesByStrand = async () => {
   //    try {
   //       const response = await fetch(`/user/courses-by-strand/${user.strand}`);
   //       const data = await response.json();
   //       console.log(data);
   //       if (response.status === 200) setStrandBasedRecommendedCourses(data.courses);
   //    } catch (err) {
   //       console.error(err.message);
   //    }
   // };

   useEffect(() => {
      handleMessagesScrollToBottom();
   }, [messages, showBot]);

   useEffect(() => {
      if (cookies.get('termsCondition') !== '' && cookies.get('termsCondition') !== 'false') setIsAgreeTermsConditions(false);
      else setIsAgreeTermsConditions(true);
   }, []);

   return (
      <>
         {showBot ? (
            <div className='chatbot shadow'>
               {/* chatbot header */}
               <div className='chatbot-header d-flex justify-content-between align-items-center bg-primary'>
                  <div>
                     <img className='chatbot-avatar' src={chathead} alt='chathead' />
                     <h2 className='ms-2 h6 d-inline custom-heading'>Anna</h2>
                  </div>
                  <MdClose className='chatbot-close' onClick={() => setShowbot(false)} />
               </div>
               {/* chatbot messages */}
               <div ref={messagesRef} className='chatbot-messages'>
                  {/* <button className='btn btn-primary' onClick={() => handleRiasecRecommendation(riasec)}>
                     Identify RIASEC Area
                  </button> */}

                  {renderMessages(messages)}
                  {/* <div ref={messageEnd}></div> */}
                  {botChatLoading && (
                     <div className='message bot'>
                        <div>
                           <img className='chatbot-avatar message-avatar' src={chatbotAvatar} alt='chathead' />
                        </div>
                        <div className='message-text bot'>
                           <img className='message-loading' src={chatloading} alt='loading' />
                        </div>
                     </div>
                  )}
               </div>
               {/* text-input */}
               <form className='chatbot-text-input' onSubmit={send}>
                  <input
                     ref={inputRef}
                     className={`${isVisibleInput ? 'visible' : 'invisible'}`}
                     disabled={!isAgreeTermsConditions || disabledInput ? true : false}
                     value={textMessage}
                     type='text'
                     placeholder='Your answer here...'
                     onChange={e => setTextMessage(e.target.value)}
                  />
                  <button className='btn p-0 chatbot-send' disabled={!textMessage ? true : false} type='submit'>
                     <MdSend className={`chatbot-send text-primary ${isVisibleInput ? 'visible' : 'invisible'}`} />
                  </button>
               </form>
            </div>
         ) : (
            <div className='chathead-container'>
               <div className='chathead-message'>Hi! Chat with me 😊</div>
               <img className='chathead' src={chathead} alt='chathead' onClick={() => setShowbot(true)} />
            </div>
         )}

     {/* terms & conditions modal */}
<Modal title='Terms and Conditions' target='modal-terms-conditions' size='modal-lg'>
   <div className='p-2'>
      <p>In using Anna, you agree to these terms and conditions:</p>
      <ol className='m-0' type='A'>
         <li>All responses and correspondences with Anna will be recorded.</li>
         <li>
            Information such as name (required), age (required), sex (required), senior high school strand (required), and related
            correspondence will be for the exclusive use of this study to continuously improve Anna.
         </li>
         <li>The data collected will be used for as long as it is needed for further analysis or investigation.</li>
         <li>You are free to exit the conversation with Anna if you feel the need to do so.</li>
      </ol>
   </div>

   <div className='p-2'>
      <h1 className='h5 custom-heading text-primary'>USER GUIDELINES:</h1>
      <p>Anna could only converse in the English language. It is then recommended that your responses be in English.</p>
      <p>
         If the user is idle for more than 20 minutes, Anna would end the conversation by replying with phrases like, "I think I lost you
         there. Please do reach out to me again anytime. I'll be here 😊". If this happens, greeting Anna with words like "Hello", or "Hi",
         will start a new conversation.
      </p>
      <p className='mb-1'>
         If any problems occur during the conversation process, or you have any suggestions or comments you would like to share with the
         researchers, please leave a feedback
         <a className='text-primary ms-1' href='/#feedback'>
            here
         </a>
         . Your insights and suggestions would help improve our project.
      </p>
   </div>

   <div className='p-2'>
      <h1 className='h5 custom-heading text-primary'>CONFIDENTIALITY</h1>
      <p>
         The information that Anna will be obtaining throughout the conversation will remain confidential to protect your rights or welfare.
      </p>
      <p>
         RA 10173 or the Data Privacy Act protects individuals from unauthorized processing of personal information. To ensure that your
         information is protected, the researchers will follow this law to keep your information safe and confidential.
      </p>
   </div>

   <div className='p-2'>
      <h1 className='h5 custom-heading text-primary'>DEFINITIONS</h1>
      <p>
         Throughout the conversation, Anna will be responding to possible jargons. To ensure that you understand Anna, the definition of
         words will be provided:
      </p>
      <p className='mb-1'>
         <span className='fw-bold'>Degree Program</span> - A class that a college or university offers to students. (Bachelor of Science in
         Information Technology, etc..)
      </p>
      <p className='mb-1'>
         <span className='fw-bold'>RIASEC</span> - A personality test that asks about your interest, skills, ability, and aspirations which
         will help you decide on what career to pursue based on these attributes.
      </p>
      <p className='mb-1'>
         <span className='fw-bold'>Senior high school strand</span> - Disciplines that are offered by schools to senior high school students
         that would prepare them for college.
      </p>
   </div>

   {!isAgreeTermsConditions && (
      <div className='form-check m-2'>
         <input
            className='form-check-input'
            onChange={() => handleTermsConditionAgree()}
            type='checkbox'
            value=''
            id='terms-conditions-check'
         />
         <label className='form-check-label fw-bold' htmlFor='terms-conditions-check'>
            I Agree to the Terms and Conditions
         </label>
      </div>
   )}

   <div className='mt-3 float-end'>
      <button className='btn btn-primary' data-bs-dismiss='modal'>
         Close
      </button>
   </div>
</Modal>

         <Modal
            title={`${basis === 'riasec' ? 'RIASEC' : 'Strand'} | Recommended Degree Programs`}
            target='modal-recommended-courses-info'
            size='modal-lg'
         >
            <div className='d-flex flex-column'>
               {basis === 'riasec'
                  ? riasecBasedRecommendedCourses.length > 0 &&
                    riasecBasedRecommendedCourses.map((course, i) => (
                       <div
                          key={i}
                          className={`course-recommendation border-bottom ${knownCourses.includes(course) ? 'active' : ''} `}
                          data-bs-dismiss='modal'
                          onClick={() => handleRecommendedCourseClick(course)}
                       >
                          <FaUserGraduate className='me-2' />
                          <span>{course}</span>
                       </div>
                    ))
                  : strandBasedRecommendedCourses.length > 0 &&
                    strandBasedRecommendedCourses.map((course, i) => (
                       <div
                          key={i}
                          className={`course-recommendation border-bottom ${knownCourses.includes(course) ? 'active' : ''} `}
                          data-bs-dismiss='modal'
                          onClick={() => handleRecommendedCourseClick(course)}
                       >
                          <FaUserGraduate className='me-2' />
                          <span>{course}</span>
                       </div>
                    ))}
            </div>
         </Modal>
          {/* Feedback Modal */}
    <Feedback
      show={showFeedbackModal}
      onClose={() => setShowFeedbackModal(false)}
      isLoggedIn={isLoggedIn}
    />
      </>
   );
};

export default Chatbot;