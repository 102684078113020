import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Modal from '../Modal';
import api from '../../utils/api';

const Feedback = ({ show, onClose, isLoggedIn }) => {
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFeedbackSubmit = async () => {
    if (!feedback.trim()) {
      toast.error('Feedback cannot be empty.');
      return;
    }

    setLoading(true);
    try {
      const endpoint = isLoggedIn ? '/feedback/' : '/guest-feedback/';
      const headers = isLoggedIn
        ? { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        : {};

      await api.post(endpoint, { feedback }, { headers });

      toast.success('Feedback submitted successfully!');
      setFeedback('');
      onClose();
    } catch (error) {
      console.error('Error submitting feedback:', error);
      toast.error('Failed to submit feedback.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal title="Feedback" target="feedback-modal" show={show} onHide={onClose}>
      <div className="p-3">
        <textarea
          className="form-control mb-3"
          rows="5"
          placeholder="Tell us how we can improve..."
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        ></textarea>
        <button
          className="btn btn-primary"
          onClick={handleFeedbackSubmit}
          disabled={loading}
        >
          {loading ? 'Submitting...' : 'Submit'}
        </button>
      </div>
    </Modal>
  );
};

export default Feedback;
