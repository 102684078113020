import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Form, Button, Nav, Navbar, Spinner, Alert } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import FileSaver from 'file-saver';
import { CSVLink } from 'react-csv';
import html2canvas from 'html2canvas';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaCommentDots, FaClipboardList } from 'react-icons/fa';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import api from '../../utils/api';  // Import the API utility
import { UserContext } from '../../context/UserContext';  // Add this import
import { useContext } from 'react';  // Add this line
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const validateUserRole = async (navigate, handleLogout) => {
  try {
    const token = localStorage.getItem('authToken');
    if (!token) throw new Error('No token found');

    const response = await api.get('/check_login_status/', {
      headers: { Authorization: `Bearer ${token}` },
    });

    const user = response.data.user_data;
    if (!user.is_staff && !user.is_superuser) {
      throw new Error('Unauthorized access');
    }
  } catch (error) {
    console.error('Role validation failed:', error);
    localStorage.clear();
    handleLogout();
    toast.error('Unauthorized access. You have been logged out.');
    navigate('/auth/guidance-login', { replace: true });
  }
};

// Register Chart.js components and plugins
ChartJS.register(...registerables, ChartDataLabels);
// Token refresh function
// Token refresh function
const refreshAccessToken = async (navigate, handleLogout) => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      throw new Error('No refresh token found');
    }

    const response = await api.post('/token/refresh/', { refresh: refreshToken });

    if (response.status === 200) {
      localStorage.setItem('authToken', response.data.access);
      return response.data.access;
    } else {
      throw new Error('Invalid or expired refresh token');
    }
  } catch (err) {
    console.error('Error refreshing access token:', err.message);
    toast.error('Session expired. Please log in again.');
    handleLogout();  // Use handleLogout to clear session and navigate
    return null;
  }
};



const chartOptionsWithAdjustedPadding = {
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: 2,  // Increase aspect ratio to make the chart wider
  layout: {
    padding: {
      top: 20,
      bottom: 20,  // Add some padding to the bottom
      left: 20,
      right: 20,
    },
  },
  plugins: {
    legend: {
      display: true,
      position: 'bottom',  // Move the legend to the bottom
    },
    datalabels: {
      anchor: 'end',
      align: 'top',
      color: '#333',
      font: {
        weight: 'bold',
        size: 12,  // Adjust font size
      },
      formatter: (value) => value,
      offset: 4,  // Slight offset to prevent overlap
    },
  },
  scales: {
    x: {
      barThickness: 15,  // Reduce bar thickness to make the chart more compact
      maxBarThickness: 20,  // Set a maximum bar thickness
    },
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 5,
      },
    },
  },
};


const Dashboard = () => {
  const { handleLogout } = useContext(UserContext);  // Destructure handleLogout from context
  const [dashboardData, setDashboardData] = useState([]);
  const [sections, setSections] = useState([]);
  const chartContainerRef = useRef(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    
    gradeLevel: 'Overall',
    strand: 'Overall',
    schoolYear: 'Overall',
    section: 'Overall', // Add section filter
  });
  
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  // Function to toggle sidebar state
  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const navigate = useNavigate();
  const chartRef = useRef(null);
  const fetchData = async () => {
    try {
      await validateUserRole(navigate, handleLogout); // Pass navigate and handleLogout
  
      let accessToken = localStorage.getItem('authToken');
      const queryParams = new URLSearchParams({
        gradeLevel: filters.gradeLevel !== 'Overall' ? filters.gradeLevel : '',
        strand: filters.strand !== 'Overall' ? filters.strand : '',
        school_year: filters.schoolYear !== 'Overall' ? filters.schoolYear : '',
        section: filters.section !== 'Overall' ? filters.section : '',
      });
  
      const response = await api.get(`/api/dashboard/?${queryParams.toString()}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
  
      if (response.status === 200) {
        setDashboardData(response.data.conversations || []);
        setSections(response.data.sections || []);
      } else if (response.status === 401) {
        accessToken = await refreshAccessToken(navigate, handleLogout);
  
        if (accessToken) {
          const retryResponse = await api.get(`/api/dashboard/?${queryParams.toString()}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
          });
  
          setDashboardData(retryResponse.data.conversations || []);
          setSections(retryResponse.data.sections || []);
        } else {
          console.error('Token refresh failed');
        }
      }
    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError('Failed to load dashboard data.');
      navigate('/auth/guidance-login');
    } finally {
      setIsLoading(false);
    }
  };
  
  
  useEffect(() => {
    fetchData();
  }, [filters, navigate, handleLogout]); // Add all dependencies used within fetchData
  
  
  // Render loading state
  // Render loading state
if (isLoading) {
  return (
    <div className="text-center mt-5">
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
      <p>Loading dashboard...</p>
    </div>
  );
}


  // Filter handling
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };
  
  const getFilteredData = () => {
    let filteredData = dashboardData;
  
    // Apply grade level filter
    if (filters.gradeLevel !== 'Overall') {
      filteredData = filteredData.filter((d) => d.grade_level === filters.gradeLevel);
    }
  
    // Apply strand filter
    if (filters.strand !== 'Overall') {
      filteredData = filteredData.filter((d) => d.strand.toUpperCase() === filters.strand.toUpperCase());
    }
  
    // Apply school year filter
    if (filters.schoolYear !== 'Overall') {
      filteredData = filteredData.filter((d) => d.school_year?.trim() === filters.schoolYear.trim());
    }
  
    // Apply section filter
    if (filters.section !== 'Overall') {
      filteredData = filteredData.filter((d) => d.section?.trim() === filters.section.trim());
    }
  
    return filteredData;
  };
  
  const getAverageRiasecPerSection = () => {
    const filteredData = getFilteredData();
    const sectionScores = {};
  
    // Aggregate RIASEC scores for each section
    filteredData.forEach((conversation) => {
      const section = conversation.section?.trim() || 'No Section';
  
      if (!sectionScores[section]) {
        sectionScores[section] = {
          realistic: 0,
          investigative: 0,
          artistic: 0,
          social: 0,
          enterprising: 0,
          conventional: 0,
          count: 0, // Track number of students per section
        };
      }
  
      sectionScores[section].realistic += conversation.realistic_score || 0;
      sectionScores[section].investigative += conversation.investigative_score || 0;
      sectionScores[section].artistic += conversation.artistic_score || 0;
      sectionScores[section].social += conversation.social_score || 0;
      sectionScores[section].enterprising += conversation.enterprising_score || 0;
      sectionScores[section].conventional += conversation.conventional_score || 0;
      sectionScores[section].count += 1;
    });
  
    // Calculate rounded averages for each section
    const sectionLabels = Object.keys(sectionScores);
    const averages = {
      realistic: [],
      investigative: [],
      artistic: [],
      social: [],
      enterprising: [],
      conventional: [],
    };
  
    sectionLabels.forEach((section) => {
      const data = sectionScores[section];
      if (data.count > 0) {
        averages.realistic.push(Number((data.realistic / data.count).toFixed(2)));
        averages.investigative.push(Number((data.investigative / data.count).toFixed(2)));
        averages.artistic.push(Number((data.artistic / data.count).toFixed(2)));
        averages.social.push(Number((data.social / data.count).toFixed(2)));
        averages.enterprising.push(Number((data.enterprising / data.count).toFixed(2)));
        averages.conventional.push(Number((data.conventional / data.count).toFixed(2)));
      } else {
        averages.realistic.push(0);
        averages.investigative.push(0);
        averages.artistic.push(0);
        averages.social.push(0);
        averages.enterprising.push(0);
        averages.conventional.push(0);
      }
    });
  
    return {
      labels: sectionLabels,
      datasets: [
        {
          label: 'Realistic',
          data: averages.realistic,
          backgroundColor: '#28a745',
        },
        {
          label: 'Investigative',
          data: averages.investigative,
          backgroundColor: '#ffc107',
        },
        {
          label: 'Artistic',
          data: averages.artistic,
          backgroundColor: '#17a2b8',
        },
        {
          label: 'Social',
          data: averages.social,
          backgroundColor: '#6c757d',
        },
        {
          label: 'Enterprising',
          data: averages.enterprising,
          backgroundColor: '#fd7e14',
        },
        {
          label: 'Conventional',
          data: averages.conventional,
          backgroundColor: '#007bff',
        },
      ],
    };
  };
  
  
const getRiasecChartData = () => {
  const filteredData = getFilteredData();

  const totalScores = {
    realistic: 0,
    investigative: 0,
    artistic: 0,
    social: 0,
    enterprising: 0,
    conventional: 0,
  };

  const strandScores = {
    ABM: { realistic: 0, investigative: 0, artistic: 0, social: 0, enterprising: 0, conventional: 0 },
    "ARTS & DESIGN": { realistic: 0, investigative: 0, artistic: 0, social: 0, enterprising: 0, conventional: 0 },
    STEM: { realistic: 0, investigative: 0, artistic: 0, social: 0, enterprising: 0, conventional: 0 },
    HUMSS: { realistic: 0, investigative: 0, artistic: 0, social: 0, enterprising: 0, conventional: 0 },
    "TVL - Information and Communications Technology": { realistic: 0, investigative: 0, artistic: 0, social: 0, enterprising: 0, conventional: 0 },
    "TVL - Home Economics": { realistic: 0, investigative: 0, artistic: 0, social: 0, enterprising: 0, conventional: 0 },
    "TVL - Agri-Fishery Arts": { realistic: 0, investigative: 0, artistic: 0, social: 0, enterprising: 0, conventional: 0 },
    "TVL - Industrial Arts": { realistic: 0, investigative: 0, artistic: 0, social: 0, enterprising: 0, conventional: 0 },
  };
  
  filteredData.forEach((conversation) => {
    totalScores.realistic += conversation.realistic_score;
    totalScores.investigative += conversation.investigative_score;
    totalScores.artistic += conversation.artistic_score;
    totalScores.social += conversation.social_score;
    totalScores.enterprising += conversation.enterprising_score;
    totalScores.conventional += conversation.conventional_score;
  
    // Directly match strand names
    const strand = conversation.strand.trim(); // Ensure no trailing spaces
    if (strandScores[strand]) {
      strandScores[strand].realistic += conversation.realistic_score || 0;
      strandScores[strand].investigative += conversation.investigative_score || 0;
      strandScores[strand].artistic += conversation.artistic_score || 0;
      strandScores[strand].social += conversation.social_score || 0;
      strandScores[strand].enterprising += conversation.enterprising_score || 0;
      strandScores[strand].conventional += conversation.conventional_score || 0;
    }
  });
  
  return {
    labels: ['Realistic', 'Investigative', 'Artistic', 'Social', 'Enterprising', 'Conventional'],
    datasets: [
      {
        label: 'Total',
        data: [
          totalScores.realistic,
          totalScores.investigative,
          totalScores.artistic,
          totalScores.social,
          totalScores.enterprising,
          totalScores.conventional,
        ],
        backgroundColor: '#28a745',
        borderColor: '#333',
        borderWidth: 2,
        borderRadius: 5,
        hoverBackgroundColor: '#555',
      },
      {
        label: 'ABM',
        data: [
          strandScores.ABM.realistic,
          strandScores.ABM.investigative,
          strandScores.ABM.artistic,
          strandScores.ABM.social,
          strandScores.ABM.enterprising,
          strandScores.ABM.conventional,
        ],
        backgroundColor: '#fd7e14',
        hidden: true,
      },
      {
        label: 'ARTS & DESIGN',
        data: [
          strandScores["ARTS & DESIGN"].realistic,
          strandScores["ARTS & DESIGN"].investigative,
          strandScores["ARTS & DESIGN"].artistic,
          strandScores["ARTS & DESIGN"].social,
          strandScores["ARTS & DESIGN"].enterprising,
          strandScores["ARTS & DESIGN"].conventional,
        ],
        backgroundColor: '#17a2b8',
        hidden: true,
      },
      {
        label: 'STEM',
        data: [
          strandScores.STEM.realistic,
          strandScores.STEM.investigative,
          strandScores.STEM.artistic,
          strandScores.STEM.social,
          strandScores.STEM.enterprising,
          strandScores.STEM.conventional,
        ],
        backgroundColor: '#ffc107',
        hidden: true,
      },
        {
          label: 'HUMSS',
          data: [
            strandScores.HUMSS.realistic,
            strandScores.HUMSS.investigative,
            strandScores.HUMSS.artistic,
            strandScores.HUMSS.social,
            strandScores.HUMSS.enterprising,
            strandScores.HUMSS.conventional,
          ],
          backgroundColor: '#dc3545',
          hidden: true,
        },
        {
          label: 'TVL - Information and Communications Technology',
          data: [
            strandScores['TVL - Information and Communications Technology'].realistic,
            strandScores['TVL - Information and Communications Technology'].investigative,
            strandScores['TVL - Information and Communications Technology'].artistic,
            strandScores['TVL - Information and Communications Technology'].social,
            strandScores['TVL - Information and Communications Technology'].enterprising,
            strandScores['TVL - Information and Communications Technology'].conventional,
          ],
          backgroundColor: '#007bff',
          hidden: true,
        },
        {
          label: 'TVL - Home Economics',
          data: [
            strandScores['TVL - Home Economics'].realistic,
            strandScores['TVL - Home Economics'].investigative,
            strandScores['TVL - Home Economics'].artistic,
            strandScores['TVL - Home Economics'].social,
            strandScores['TVL - Home Economics'].enterprising,
            strandScores['TVL - Home Economics'].conventional,
          ],
          backgroundColor: '#6c757d',
          hidden: true,
        },
        {
          label: 'TVL - Agri-Fishery Arts',
          data: [
            strandScores['TVL - Agri-Fishery Arts'].realistic,
            strandScores['TVL - Agri-Fishery Arts'].investigative,
            strandScores['TVL - Agri-Fishery Arts'].artistic,
            strandScores['TVL - Agri-Fishery Arts'].social,
            strandScores['TVL - Agri-Fishery Arts'].enterprising,
            strandScores['TVL - Agri-Fishery Arts'].conventional,
          ],
          backgroundColor: '#20c997',
          hidden: true,
        },
        {
          label: 'TVL - Industrial Arts',
          data: [
            strandScores['TVL - Industrial Arts'].realistic,
            strandScores['TVL - Industrial Arts'].investigative,
            strandScores['TVL - Industrial Arts'].artistic,
            strandScores['TVL - Industrial Arts'].social,
            strandScores['TVL - Industrial Arts'].enterprising,
            strandScores['TVL - Industrial Arts'].conventional,
          ],
          backgroundColor: '#ffc0cb',
          hidden: true,
        },
      ],
    };
  };

  const getSexChartData = () => {
    const filteredData = getFilteredData();
    let maleCount = 0;
    let femaleCount = 0;
  
    filteredData.forEach((conversation) => {
      if (conversation.sex === 'Male') {
        maleCount += 1;
      } else if (conversation.sex === 'Female') {
        femaleCount += 1;
      }
    });
  
    const totalCount = maleCount + femaleCount;
  
    return {
      labels: ['Male', 'Female', 'Total'],
      datasets: [
        {
          label: 'Gender Distribution',
          data: [maleCount, femaleCount, totalCount],
          backgroundColor: ['#007bff', '#ff6ec7', '#4caf50'],  // Add a color for Total
          borderColor: '#333',
          borderWidth: 2,
          hoverBackgroundColor: ['#0056b3', '#ff3c7f', '#388e3c'],  // Hover colors including Total
          borderRadius: 5,
        },
      ],
    };
  };
  
  const getGradeLevelChartData = () => {
    const filteredData = getFilteredData();
    let grade11Count = 0;
    let grade12Count = 0;
  
    filteredData.forEach((conversation) => {
      if (conversation.grade_level === '11') {
        grade11Count += 1;
      } else if (conversation.grade_level === '12') {
        grade12Count += 1;
      }
    });
  
    const totalCount = grade11Count + grade12Count;
  
    return {
      labels: ['Grade 11', 'Grade 12', 'Total'],
      datasets: [
        {
          label: 'Grade Level Distribution',
          data: [grade11Count, grade12Count, totalCount],
          backgroundColor: ['#ff6384', '#36a2eb', '#4caf50'],  // Add a color for Total
          borderColor: '#333',
          borderWidth: 2,
          hoverBackgroundColor: ['#ff1c4b', '#1c83d1', '#388e3c'],  // Hover colors including Total
          borderRadius: 5,
        },
      ],
    };
  };
  

  const downloadPNG = async () => {
    try {
      const canvas = await html2canvas(chartContainerRef.current, { scrollY: -window.scrollY });
      canvas.toBlob((blob) => {
        FileSaver.saveAs(blob, 'dashboard_charts.png');
      });
    } catch (err) {
      console.error('Error capturing charts:', err);
    }
  };
  

  // CSV data for download
  const csvData = dashboardData.map((row) => ({
    Name: row.name,
    Sex: row.sex,
    Strand: row.strand,
    'Realistic Score': row.realistic_score,
    'Investigative Score': row.investigative_score,
    'Artistic Score': row.artistic_score,
    'Social Score': row.social_score,
    'Enterprising Score': row.enterprising_score,
    'Conventional Score': row.conventional_score,
  }));
  return (
    
    <Container fluid>
       {/* Display Error Alert if there's an error */}
    {error && (
      <Alert variant="danger" className="text-center my-4">
        {error}
      </Alert>
    )}

    <Row>
      {/* Main Content */}
      <Col xs={12} className="p-4">
        {/* Header Section */}
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3 className="fw-bold">Dashboard</h3>
          <DropdownButton
            id="dropdown-export-button"
            title="Export"
            variant="warning"
            className="me-2"
          >
            <Dropdown.Item onClick={downloadPNG}>Download All Charts (PNG)</Dropdown.Item>
            <Dropdown.Item as={CSVLink} data={csvData} filename="dashboard_data.csv">
              Download CSV
            </Dropdown.Item>
          </DropdownButton>
        </div>
  
        {/* Filters Section */}
        <Row className="mb-4">
          <Col md={3} style={{ maxWidth: '300px' }}>
            <Form.Group>
              <Form.Label>Grade Level</Form.Label>
              <Form.Select
                name="gradeLevel"
                value={filters.gradeLevel}
                onChange={handleFilterChange}
                className="w-100"
              >
                <option value="Overall">Overall</option>
                <option value="11">Grade 11</option>
                <option value="12">Grade 12</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3} style={{ maxWidth: '300px' }}>
            <Form.Group>
              <Form.Label>Strand</Form.Label>
              <Form.Select
                name="strand"
                value={filters.strand}
                onChange={handleFilterChange}
                className="w-100"
              >
                <option value="Overall">Overall</option>
                <option value="STEM">STEM</option>
                <option value="ABM">ABM</option>
                <option value="ARTS & DESIGN">ARTS & DESIGN</option>
                <option value="HUMSS">HUMSS</option>
                <option value="TVL - Information and Communications Technology">
                  TVL - Information and Communications Technology
                </option>
                <option value="TVL - Home Economics">TVL - Home Economics</option>
                <option value="TVL - Agri-Fishery Arts">TVL - Agri-Fishery Arts</option>
                <option value="TVL - Industrial Arts">TVL - Industrial Arts</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3} style={{ maxWidth: '300px' }}>
            <Form.Group>
              <Form.Label>School Year</Form.Label>
              <Form.Select
                name="schoolYear"
                value={filters.schoolYear}
                onChange={handleFilterChange}
                className="w-100"
              >
                <option value="Overall">Overall</option>
                <option value="2022-2023">2022-2023</option>
                <option value="2023-2024">2023-2024</option>
                <option value="2024-2025">2024-2025</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3} style={{ maxWidth: '300px' }}>
            <Form.Group>
              <Form.Label>Section</Form.Label>
              <Form.Select
                name="section"
                value={filters.section}
                onChange={handleFilterChange}
                className="w-100"
              >
                <option value="Overall">Overall</option>
                {sections.map((section) => (
                  <option key={section.id} value={section.name}>
                    {section.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
  
        {/* Charts Section */}
        <div ref={chartContainerRef}>
          <Row>
            <Col md={6} className="mb-4" style={{ maxWidth: '650px' }}>
              <div style={{ height: '450px' }}>
                <Bar
                  data={getRiasecChartData()}
                  options={{
                    ...chartOptionsWithAdjustedPadding,
                    plugins: {
                      ...chartOptionsWithAdjustedPadding.plugins,
                      title: {
                        display: true,
                        text: 'RIASEC Distribution',
                      },
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={6} className="mb-4" style={{ maxWidth: '650px' }}>
              <div style={{ height: '450px' }}>
                <Bar
                  data={getSexChartData()}
                  options={{
                    ...chartOptionsWithAdjustedPadding,
                    plugins: {
                      ...chartOptionsWithAdjustedPadding.plugins,
                      title: {
                        display: true,
                        text: 'Gender Distribution',
                      },
                    },
                  }}
                />
              </div>
            </Col>
          </Row>
  
          {/* Average RIASEC Scores and Grade Level Charts Section */}
          <Row>
            <Col md={6} className="mb-4" style={{ maxWidth: '800px' }}>
              <div style={{ height: '450px' }}>
                <Bar
                  data={getAverageRiasecPerSection()}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: true,
                        position: 'bottom',
                      },
                      title: {
                        display: true,
                        text: 'Average RIASEC Scores Per Section',
                      },
                    },
                    scales: {
                      y: {
                        beginAtZero: true,
                        title: {
                          display: true,
                          text: 'Average Score',
                        },
                      },
                      x: {
                        title: {
                          display: true,
                          text: 'Sections',
                        },
                      },
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={6} className="mb-4" style={{ maxWidth: '650px' }}>
              <div style={{ height: '450px' }}>
                <Bar
                  data={getGradeLevelChartData()}
                  options={{
                    ...chartOptionsWithAdjustedPadding,
                    plugins: {
                      ...chartOptionsWithAdjustedPadding.plugins,
                      title: {
                        display: true,
                        text: 'Grade Level Distribution',
                      },
                    },
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  </Container>
  
  


  );
};
export default Dashboard;
