import React, { useState, useEffect, useContext } from 'react';
import { Table, Container, Alert, Spinner } from 'react-bootstrap';
import { UserContext } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../utils/api'; // Import the Axios instance

const Feedback = () => {
  const [feedbackList, setFeedbackList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { handleLogout } = useContext(UserContext);
  const navigate = useNavigate();

  // Fetch feedbacks using api.js
  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        const response = await api.get('/api/feedbacks'); // Fetch feedbacks using Axios
        setFeedbackList(response.data.feedbacks || []);
      } catch (error) {
        if (error.response?.status === 401) {
          toast.error('Session expired. Please log in again.');
          handleLogout();
        } else {
          console.error('Error fetching feedbacks:', error);
          toast.error('Failed to load feedbacks.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchFeedbacks();
  }, [handleLogout, navigate]);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
        <p>Loading feedback...</p>
      </div>
    );
  }

  if (!feedbackList || feedbackList.length === 0) {
    return (
      <div className="text-center mt-5">
        <Alert variant="info">No feedback found</Alert>
      </div>
    );
  }

  return (
    <Container className="mt-5">
      <h3 className="text-center mb-4">User Feedback</h3>
      <Table striped bordered hover responsive>
        <thead className="table-dark">
          <tr>
            <th>Email</th>
            <th>Feedback</th>
          </tr>
        </thead>
        <tbody>
          {feedbackList.map((feedback, index) => (
            <tr key={index}>
              <td>{feedback.email}</td>
              <td>{feedback.feedback}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Feedback;
