import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function CustomNavbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  // Determine the title based on the current path
  const getTitle = () => {
    switch (location.pathname) {
      case "/result":
        return "Results";
      case "/account":
        return "Manage Your Profile";
      default:
        return "Manage Your Profile";
    }
  };

  return (
    <Navbar
      bg="warning"
      expand="lg"
      className="shadow-sm"
      style={{ padding: "10px 20px" }}
    >
      <Container>
        <div
          className="d-flex w-100 align-items-center justify-content-between position-relative"
          style={{
            height: "50px", // Ensures consistent height for alignment
          }}
        >
          {/* Back Button (Visible on All Screen Sizes) */}
          {location.pathname !== "/" && (
  <div
    className="d-flex align-items-center justify-content-center"
    style={{
      position: "absolute",
      left: "0",
      width: window.innerWidth <= 768 ? "35px" : "40px", // Adjust width for mobile
      height: window.innerWidth <= 768 ? "35px" : "40px", // Adjust height for mobile
    }}
  >
    <Button
      variant="light"
      className="rounded-circle d-flex align-items-center justify-content-center"
      onClick={() => navigate("/")}
      style={{
        width: "100%", // Use the parent div's width
        height: "100%", // Use the parent div's height
      }}
    >
      <i
        className="bi bi-arrow-left-circle-fill"
        style={{
          fontSize: window.innerWidth <= 768 ? "1.25rem" : "1.5rem", // Adjust icon size
          color: "#ffcc00",
        }}
      ></i>
    </Button>
  </div>
)}



          {/* Title Section */}
          <Navbar.Brand
            className="fw-bold text-white mx-auto text-center"
            style={{
              fontSize: "1.5rem",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)", // Ensures proper centering on both views
            }}
          >
            {getTitle()}
          </Navbar.Brand>

          {/* Navbar Toggle (for Mobile) */}
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="border-0"
            onClick={() => setIsNavCollapsed(!isNavCollapsed)}
            style={{
              outline: "none",
              boxShadow: "none",
              position: "absolute",
              right: "0", // Aligns to the right
            }}
          />
        </div>

        {/* Navbar Links */}
        <Navbar.Collapse
          id="basic-navbar-nav"
          className={`mt-3 mt-lg-0 ${!isNavCollapsed ? "show" : ""}`}
        >
          <Nav className="ms-auto w-100 d-flex flex-column flex-lg-row justify-content-center align-items-center">
            <Button
              variant={
                location.pathname === "/account" ? "light" : "outline-light"
              }
              className="mb-2 mb-lg-0 mx-lg-2"
              onClick={() => navigate("/account")}
              style={{
                minWidth: "150px",
              }}
            >
              Account Details
            </Button>
            <Button
              variant={
                location.pathname === "/result" ? "light" : "outline-light"
              }
              className="mb-2 mb-lg-0 mx-lg-2"
              onClick={() => navigate("/result")}
              style={{
                minWidth: "150px",
              }}
            >
              Results
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CustomNavbar;
